import * as React from 'react';
import {Button} from 'react-bootstrap';
import {faListAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import {
    faAngleRight,
    faBars,
    faBolt,
    faCabinetFiling,
    faCheckCircle,
    faClipboardList,
    faEnvelope,
    faExchangeAlt,
    faFileUpload,
    faHexagon,
    faHistory,
    faList,
    faPhoneSquare,
    faSyncAlt,
    faToolbox,
    faUserCircle,
    faMegaphone,
} from '@fortawesome/pro-solid-svg-icons';

import {AccountEntryModal} from '../../authentication/AccountEntryModal/AccountEntryModal';
import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {BodyScroll} from '../../ui/ScrollHandler';
import {FlyoutMenu} from '../../ui/Flyouts/FlyoutMenu';
import {gaLegacyCustomEvent, gaLegacyDataLayerPush} from '../../../client/ga/ga-legacy.functions';
import {getReactNativeVersion} from '../../user-agent/user-agent.helpers';
import {HeaderMobileMenuStyle} from './HeaderMobileMenu.style';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {LinkButton} from '../../components/LinkButton/LinkButton';
import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {ProductCategories} from '../mega-menu/ProductCategories/ProductCategories';
import {Radio} from '../../ui/forms/Radio/Radio';
import {UserAgent} from '../../../client/user-agent/user-agent.class';
import {UsersService} from '../../../client/users/users.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface HeaderMobileMenuProps {
    user: User;
}

export const HeaderMobileMenu = ({user}: HeaderMobileMenuProps) => {
    const [flyoutTitle, setFlyoutTitle] = useState(``);
    const [isSubMenu, setIsSubMenu] = useState(false);
    const [showAccountEntryModal, setShowAccountEntryModal] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [showMyShopMenu, setShowMyShopMenu] = useState(false);
    const [showProductsMenu, setShowProductsMenu] = useState(false);
    const [topMenuOpen, setTopMenuOpen] = useState(false);
    const componentName = 'header-mobile-menu-component';
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);
    const usersService: UsersService = useService(`usersService`);

    /**
     * TBD
     */
    const createNewOrder = () => {
        gaLegacyCustomEvent({eventAction: `create_new_order`, eventCategory: `Ecommerce`, eventLabel: `orders_menu`});
        ordersWorkflow.createOrderNavOrderDetail(componentName).subscribe({
            error: (createOrderErr: ImpError) => {
                alert(createOrderErr.message);
            },
        });
    };

    /**
     * Selects the user's custom catalog
     * @param catalogId
     */
    const selectCatalog = (catalogId: string) => {
        usersService
            .selectCatalog(catalogId)
            .then(() => {
                location.reload();
            })
            .catch(() => {
                // Error silently
            });
    };

    /**
     * Template
     */
    return (
        <>
            <HeaderMobileMenuStyle>
                <div
                    className="header-mobile-menu tw-p-3 tw-pr-4"
                    aria-label="Hamburger Menu Icon"
                    onClick={() => {
                        BodyScroll(false);
                        setFlyoutTitle(user.isLoggedIn() ? `Welcome` : `Log In`);
                        setIsSubMenu(false);
                        setTopMenuOpen(true);
                    }}
                >
                    <FontAwesomeIcon
                        className="gray-600 open-header-mobile-menu-icon open-header-mobile-menu"
                        icon={faBars}
                        size="xl"
                    />
                </div>
                <div className="flex-grow-1 overflow-y-auto overflow-x-none">
                    <FlyoutMenu
                        isSubMenu={isSubMenu}
                        onClose={() => {
                            BodyScroll(true);
                            setTopMenuOpen(false);
                            setShowAccountMenu(false);
                            setShowMyShopMenu(false);
                            setShowProductsMenu(false);
                        }}
                        resetMenu={() => {
                            setFlyoutTitle(user.isLoggedIn() ? `Welcome` : `Log In`);
                            setIsSubMenu(false);
                            setShowAccountMenu(false);
                            setShowMyShopMenu(false);
                            setShowProductsMenu(false);
                            setTopMenuOpen(true);
                        }}
                        show={topMenuOpen || showAccountMenu || showProductsMenu || showMyShopMenu}
                        title={flyoutTitle}
                        width={350}
                    >
                        {topMenuOpen && (
                            <div className={`top-menu${topMenuOpen ? '' : ' hide'}`}>
                                {user.isLoggedIn() && (
                                    <div className="border-bottom align-items-center px-3 py-2">
                                        <div className="maskPII">
                                            <h5>{user.fullName}</h5>
                                            <div>{user.city}</div>
                                            <div>{user.coName}</div>
                                            <div>{user.activeAccount ? `#${user.activeAccount} ` : ``}</div>
                                        </div>
                                    </div>
                                )}
                                {user.hasCustomCatalogs() && user.hasMultipleCatalogs() && (
                                    <div className="border-bottom px-3 pt-2">
                                        <h4>Catalogs</h4>
                                        {user.catalogList.catalogs.map((catalog) => (
                                            <Radio
                                                alias={`${catalog.cid}`}
                                                defaultChecked={catalog.cid.toString() === user.activeCatalogId}
                                                key={catalog.cid}
                                                label={catalog.name}
                                                name="header_catalogSelect"
                                                onChange={() => {
                                                    selectCatalog(`${catalog.cid}`);
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}
                                {!user.isLoggedIn() && (
                                    <div className="mt-3 mx-3">
                                        <Button
                                            block={true}
                                            onClick={() => {
                                                usersService.showLoginPopUp(() => {
                                                    setTopMenuOpen(false);
                                                });
                                            }}
                                            type="button"
                                            variant="secondary"
                                        >
                                            Log In
                                        </Button>
                                        <Button
                                            block={true}
                                            href="/create-account"
                                            type="button"
                                            variant="outline-secondary"
                                        >
                                            Create Account
                                        </Button>
                                    </div>
                                )}
                                <div className="px-2 pt-2">
                                    <ul className="mobile-main-menu">
                                        {user.canSwitchAccount() && (
                                            <li>
                                                <LinkButton
                                                    customStyle={{color: `#333333`}}
                                                    onClick={() => {
                                                        setTopMenuOpen(false);
                                                        BodyScroll(true);
                                                        setShowAccountFinderModal(true);
                                                    }}
                                                    text={
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faExchangeAlt}
                                                                className="text-center icon mr-2 gray-600 switch-accounts-icon"
                                                            />
                                                            <span>Switch Account</span>
                                                        </>
                                                    }
                                                />
                                            </li>
                                        )}
                                        {user.isAllAccountAccess() && (
                                            <li>
                                                <LinkButton
                                                    customStyle={{color: `#333333`}}
                                                    onClick={() => {
                                                        setTopMenuOpen(false);
                                                        BodyScroll(true);
                                                        setShowAccountEntryModal(true);
                                                    }}
                                                    text={
                                                        <>
                                                            <FontAwesomeIcon
                                                                className="text-center icon mr-2 gray-600 enter-account-icon"
                                                                icon={faExchangeAlt}
                                                            />
                                                            <span>Enter Account</span>
                                                        </>
                                                    }
                                                />
                                            </li>
                                        )}
                                        {user.hasOrderApprovalAccess && (
                                            <li>
                                                <a href="/orders/approval">
                                                    <FontAwesomeIcon
                                                        className="text-center icon mr-2 gray-600 order-approval-icon"
                                                        icon={faCheckCircle}
                                                    />
                                                    <span>Order Approval</span>
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a href="/orders">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 orders-icon"
                                                    icon={faListAlt}
                                                />
                                                <span>Order Review</span>
                                            </a>
                                        </li>
                                        {user.hasJobCosting() && (
                                            <li>
                                                <a href="/jobcosting">
                                                    <FontAwesomeIcon
                                                        className="text-center icon mr-2 gray-600 repair-orders-icon"
                                                        icon={faClipboardList}
                                                    />
                                                    <span>Repair Orders</span>
                                                </a>
                                            </li>
                                        )}
                                        {user.hasRepairOrder() && (
                                            <li>
                                                <a href="/ro">
                                                    <FontAwesomeIcon
                                                        className="text-center icon mr-2 gray-600 repair-orders-icon"
                                                        icon={faClipboardList}
                                                    />
                                                    <span>Repair Orders</span>
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            {user.isLoggedIn() ? (
                                                <>
                                                    <LinkButton
                                                        customStyle={{color: `#333333`, width: `100%`}}
                                                        onClick={() => {
                                                            setFlyoutTitle(`My Account`);
                                                            setIsSubMenu(true);
                                                            setShowAccountMenu(true);
                                                            setTopMenuOpen(false);
                                                        }}
                                                        text={
                                                            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                                                                <div>
                                                                    <FontAwesomeIcon
                                                                        className="text-center icon mr-2 gray-600 my-account-icon"
                                                                        icon={faUserCircle}
                                                                    />
                                                                    <span>My Account</span>
                                                                </div>
                                                                <FontAwesomeIcon
                                                                    className="gray-600"
                                                                    icon={faAngleRight}
                                                                    size="lg"
                                                                />
                                                            </div>
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <a href="/account">
                                                    <FontAwesomeIcon
                                                        className="text-center icon mr-2 gray-600 MyAccount-icon"
                                                        icon={faUserCircle}
                                                    />
                                                    <span>My Account</span>
                                                </a>
                                            )}
                                        </li>
                                        <li>
                                            <LinkButton
                                                customStyle={{color: `#333333`, width: `100%`}}
                                                onClick={() => {
                                                    setFlyoutTitle(`Products`);
                                                    setIsSubMenu(true);
                                                    setShowProductsMenu(true);
                                                    setTopMenuOpen(false);
                                                }}
                                                text={
                                                    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                                                        <div>
                                                            <FontAwesomeIcon
                                                                className="text-center icon mr-2 gray-600 my-account-icon"
                                                                icon={faHexagon}
                                                            />
                                                            <span>Products</span>
                                                        </div>
                                                        <FontAwesomeIcon
                                                            className="gray-600"
                                                            icon={faAngleRight}
                                                            size="lg"
                                                        />
                                                    </div>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <a href="/order-history-items">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 order-history-icon"
                                                    icon={faHistory}
                                                />
                                                <span>Order History</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/lists">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 my-lists-icon"
                                                    icon={faList}
                                                />
                                                <span>My Lists</span>
                                            </a>
                                        </li>
                                        <li>
                                            <LinkButton
                                                customStyle={{color: `#333333`, width: `100%`}}
                                                onClick={() => {
                                                    setFlyoutTitle(`My Shop`);
                                                    setIsSubMenu(true);
                                                    setShowMyShopMenu(true);
                                                    setTopMenuOpen(false);
                                                }}
                                                text={
                                                    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                                                        <div>
                                                            <FontAwesomeIcon
                                                                className="text-center icon mr-2 gray-600 my-account-icon"
                                                                icon={faCabinetFiling}
                                                            />
                                                            <span>My Shop</span>
                                                        </div>
                                                        <FontAwesomeIcon
                                                            className="gray-600"
                                                            icon={faAngleRight}
                                                            size="lg"
                                                        />
                                                    </div>
                                                }
                                            />
                                        </li>
                                        {!user.isPunchout && (
                                            <li>
                                                <a href="/manageautoreorders">
                                                    <FontAwesomeIcon
                                                        className="text-center icon mr-2 gray-600 auto-reorder-icon"
                                                        icon={faSyncAlt}
                                                    />
                                                    <span>Auto Reorder</span>
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a href="/fileimport">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 file-import-icon"
                                                    icon={faFileUpload}
                                                />
                                                <span>File Import</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/quickadd">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 quick-add-icon"
                                                    icon={faBolt}
                                                />
                                                <span>Quick-Add</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 services-icon"
                                                    icon={faToolbox}
                                                />
                                                <span>Services</span>
                                            </a>
                                        </li>
                                        <li>
                                            <LinkButton
                                                customStyle={{color: `#333333`}}
                                                onClick={() => {
                                                    gaLegacyDataLayerPush(`injectQualtricsScript`);
                                                }}
                                                text={
                                                    <>
                                                        <FontAwesomeIcon
                                                            className="icon mr-2 gray-600 feedback-icon"
                                                            icon={faMegaphone}
                                                        />
                                                        <span>Submit Feedback</span>
                                                    </>
                                                }
                                            />
                                        </li>
                                        <li className="d-block d-md-none d-lg-block">
                                            <a href="tel:1-800-558-2808">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 call-icon"
                                                    icon={faPhoneSquare}
                                                />
                                                <span>Call Imperial</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/contact-us">
                                                <FontAwesomeIcon
                                                    className="text-center icon mr-2 gray-600 contact-icon"
                                                    icon={faEnvelope}
                                                />
                                                <span>Contact Imperial</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="px-3 border-top pt-3">
                                    <h4>Shop By</h4>
                                    <ul
                                        className="mobile-sub-menu"
                                        data-e2e="shopByLinks"
                                    >
                                        {user.isLoggedIn() && (
                                            <li>
                                                <a
                                                    data-e2e="previouslyPurchasedLink"
                                                    href="/browse/?FpurchasedBy_s_M=on"
                                                >
                                                    Previously Purchased
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a
                                                data-e2e="featureProductsLink"
                                                href="/featured"
                                            >
                                                Featured Products
                                            </a>
                                        </li>
                                        {user.hasDeals() && (
                                            <li>
                                                <a href="/deals">Deals</a>
                                            </li>
                                        )}
                                        <li>
                                            <a
                                                data-e2e="emergencyPreparednessLink"
                                                href="/emergency-preparedness"
                                            >
                                                Emergency Preparedness
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-e2e="bestSellersLink"
                                                href="/browse/?FshopBy_s_M=Best%20Seller"
                                            >
                                                Best Sellers
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-e2e="allBrandsLink"
                                                href="/brands"
                                            >
                                                All Brands
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-e2e="imperialBrandLink"
                                                href="/browse/?FshopBy_s_M=Imperial%20Brand"
                                            >
                                                Imperial Brand
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-e2e="newProductsLink"
                                                href="/newproducts"
                                            >
                                                New Products
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-e2e="ClearanceLink"
                                                href="/special/3769"
                                            >
                                                Clearance
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-e2e="wiperBladeFinderLink"
                                                href="/browse/vehicle-trailer-parts/wiper-blades-and-accessories/wiper-blades"
                                            >
                                                Wiper Blade Finder
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="px-3 my-3 border-top pt-3">
                                    <h4>Shop Talk</h4>
                                    <ul className="mobile-sub-menu">
                                        <li>
                                            <a href="/shop-talk">Read Latest</a>
                                        </li>
                                    </ul>
                                </div>
                                {!UserAgent.isReactNative(`5.3.0`) && (
                                    <div className="tw-bg-gray-150 tw-flex tw-justify-between tw-p-4">
                                        Need offline mode?
                                        <a
                                            href={
                                                UserAgent.isiOS()
                                                    ? `https://itunes.apple.com/us/app/imperial-supplies/id775108692?ls=1&mt=8`
                                                    : `https://play.google.com/store/apps/details?id=com.imperial.ImperialApp`
                                            }
                                            target="_blank"
                                        >
                                            Download App
                                        </a>
                                    </div>
                                )}
                                <div className="px-3 mb-3 border-top py-3">
                                    {UserAgent.isReactNative(`5.3.0`) && (
                                        <Button
                                            block={true}
                                            className="mb-3"
                                            onClick={() => {
                                                setTopMenuOpen(false);
                                                UserAgent.postNativeMessage({data: `true`, event: `setOfflineMode`});
                                                UserAgent.postNativeMessage({
                                                    event: `navigate`,
                                                    page: `Home`,
                                                });
                                            }}
                                            title="Use Offline Mode"
                                            variant="outline-secondary"
                                        >
                                            Use Offline Mode
                                        </Button>
                                    )}
                                    {user.isLoggedIn() && (
                                        <Button
                                            block={true}
                                            href="/logout"
                                            variant="outline-secondary"
                                        >
                                            Log Out
                                        </Button>
                                    )}
                                </div>
                                {UserAgent.isReactNative(`5.3.0`) && <div className="tw-ml-4">App Version: {getReactNativeVersion()}</div>}
                                <div className="mb-10 pb-10" />
                            </div>
                        )}
                        {showProductsMenu && (
                            <div className={`sub-menu${showProductsMenu ? ' active' : ''}`}>
                                <div className="border-top" />
                                <a
                                    className="btn btn-outline-secondary mx-3 mt-4 d-block"
                                    href="/browse"
                                    role="button"
                                >
                                    Shop All
                                </a>
                                <div className="p-3">
                                    <ProductCategories source="hamburger" />
                                </div>
                                <div className="mb-10 pb-2" />
                            </div>
                        )}
                        {showAccountMenu && (
                            <>
                                <div className="border-top" />
                                {user.isLoggedIn() && (
                                    <div className="px-3">
                                        <div className="mb-4">
                                            <ul className="account-links">
                                                <li className="pb-2">
                                                    <h4>My Orders</h4>
                                                    <ul>
                                                        {user.canOrder() && (
                                                            <>
                                                                <li>
                                                                    <a href="/orders">Order Review</a>
                                                                </li>
                                                                {!user.isPunchout && (
                                                                    <li>
                                                                        <a href="/manageautoreorders">Auto-Reorder Items</a>
                                                                    </li>
                                                                )}
                                                                <li>
                                                                    <a href="/special-order">Special Order Review</a>
                                                                </li>
                                                                {user.hasOrderApprovalAccess && (
                                                                    <li>
                                                                        <a href="/orders/approval">Order Approval</a>
                                                                    </li>
                                                                )}
                                                            </>
                                                        )}
                                                        {user.canOrder() && (
                                                            <>
                                                                <li>
                                                                    <a href="/returns?numMonths=6">My Returns</a>
                                                                </li>
                                                                {user.hasActiveOrder() && !user.isPunchout ? (
                                                                    <li>
                                                                        <a href={`/orders/${ordersService.currentOrderNumber}/checkout`}>
                                                                            Check Out Order #{ordersService.currentOrderNumber}
                                                                        </a>
                                                                    </li>
                                                                ) : (
                                                                    <li>
                                                                        <a
                                                                            href="#"
                                                                            onClick={() => {
                                                                                createNewOrder();
                                                                            }}
                                                                        >
                                                                            Create Order
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </>
                                                        )}
                                                    </ul>
                                                </li>
                                                <li className="pb-2">
                                                    <h4>Invoices & Statements</h4>
                                                    <ul>
                                                        {user.canInvoice() && (
                                                            <li>
                                                                <a href="/invoices">Invoice Review</a>
                                                            </li>
                                                        )}
                                                        {user.hasARAccess() && (
                                                            <li>
                                                                <a href="/ar">Invoice Payment</a>
                                                            </li>
                                                        )}
                                                        {user.hasSalesAccess() && user.canViewPrice() && (
                                                            <li>
                                                                <a href="/discounts">Discount Review</a>
                                                            </li>
                                                        )}
                                                        {user.hasJobCosting() && (
                                                            <li>
                                                                <a href="/jobcosting">Repair Order Review</a>
                                                            </li>
                                                        )}
                                                        {user.hasRepairOrder() && (
                                                            <li>
                                                                <a href="/ro">Repair Orders</a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>
                                                <li className="pb-2">
                                                    <h4>Reporting</h4>
                                                    <ul>
                                                        {user.hasSalesAccess() && (
                                                            <>
                                                                <li>
                                                                    <a href="/product-sales">Category Purchases Report</a>
                                                                </li>
                                                                {user.activeAccount && !user.isParentLevel() && (
                                                                    <>
                                                                        <li>
                                                                            <a href="/product-sales?prod=ALLGRP">Group Fill Rate Report</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="/product-sales?prod=ALL">Item Purchases Report</a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                                {user.canViewPrice() && (
                                                                    <li>
                                                                        <a href="/slssum">Monthly Purchases Report</a>
                                                                    </li>
                                                                )}
                                                            </>
                                                        )}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mb-4">
                                            <ul className="account-links">
                                                <li className="pb-10">
                                                    <h4>Account Management</h4>
                                                    <ul>
                                                        {user.canSwitchAccount() && (
                                                            <li style={{lineHeight: `20px`}}>
                                                                <LinkButton
                                                                    customStyle={{color: `#333333`}}
                                                                    onClick={() => {
                                                                        setTopMenuOpen(false);
                                                                        BodyScroll(true);
                                                                        setShowAccountFinderModal(true);
                                                                    }}
                                                                    text={
                                                                        <>
                                                                            <span>Switch Account</span>
                                                                            <br />
                                                                            <span className="caption">
                                                                                Current:{' '}
                                                                                {user.activeAccount
                                                                                    ? user.activeAccount
                                                                                    : `No Account Selected`}
                                                                            </span>
                                                                        </>
                                                                    }
                                                                />
                                                            </li>
                                                        )}
                                                        {user.isAccountLevel() && (
                                                            <li>
                                                                <a href="/account-info">Account Review</a>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <a href="/account#change-password">Change Password</a>
                                                        </li>
                                                        <li>
                                                            <a href="/email-preferences">Email Preferences</a>
                                                        </li>
                                                        {user.invoicePrefAccess && (
                                                            <li>
                                                                <a href="/invoice-delivery-preferences">Invoice Delivery Preferences</a>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <a href="/payment-methods">Payment Methods</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                <div className="mb-10" />
                            </>
                        )}
                        {showMyShopMenu && (
                            <>
                                <div className="border-top" />
                                <div className="px-3">
                                    <div className="mb-4">
                                        <ul className="account-links">
                                            <li className="pb-2">
                                                <ul>
                                                    <li>
                                                        <a href="/my-shop/locations">Locations</a>
                                                    </li>
                                                    {UserAgent.isReactNative(`5.4.0`) && user.isFieldService() && (
                                                        <li>
                                                            <a
                                                                href="#"
                                                                onClick={() => {
                                                                    UserAgent.postNativeMessage({
                                                                        event: `navigate`,
                                                                        page: `ObsoleteItems`,
                                                                    });
                                                                }}
                                                            >
                                                                Obsolete Items
                                                            </a>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <a href="/my-shop/inventory">Inventory Items</a>
                                                    </li>
                                                    <li>
                                                        <a href="/my-shop/steel-equipment">Steel Equipment</a>
                                                    </li>
                                                    <li>
                                                        <a href="/my-sds">Safety Data Sheets</a>
                                                    </li>
                                                    <li>
                                                        <a href="/my-shop/backsheets">Backsheets</a>
                                                    </li>
                                                    {user.isFieldService() && (
                                                        <li>
                                                            <a href="/fs-photo-uploader">Upload Photos</a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mb-10" />
                            </>
                        )}
                    </FlyoutMenu>
                </div>
            </HeaderMobileMenuStyle>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
            <AccountEntryModal
                onHide={() => setShowAccountEntryModal(false)}
                show={showAccountEntryModal}
            />
        </>
    );
};
